import styled, { css } from 'styled-components';

export const TextButton = styled.button<TextButtonProps>(
  ({ theme, margin, gap }) => css`
    color: ${theme.colors.babyBlue};
    font-weight: 900;
    font-family: ${theme.fonts.lato};
    font-size: 16px;
    display: flex;
    align-items: center;
    height: 32px;
    gap: ${gap || 8}px;
    margin: ${margin || '0'};
    border: 0;
    background: transparent;
    cursor: pointer;
    padding: 0;
    transition: opacity 0.15s ease;

    @media (hover: hover) {
      &:hover {
        opacity: 0.6;
      }
    }

    & svg {
      height: 24px;
      width: 24px;
      flex-shrink: 0;
      fill: ${theme.colors.babyBlue};
    }
  `,
);

type TextButtonProps = {
  gap?: number;
  margin?: string;
};
