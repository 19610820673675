import theme from 'styles/theme';

// Function used to show the branding color of HCS is the right order in Accordions
// and other list items. The order is: blue, green, red.
export const getBrandingColor = (index: number, color?: 'blue' | 'green' | 'red') => {
  if ((color === 'green' || index % 3 === 1) && color !== 'blue' && color !== 'red') {
    return theme.colors.aquaGreen;
  }
  if ((color === 'red' || index % 3 === 2) && color !== 'blue') {
    return theme.colors.pomeloRed;
  }

  return theme.colors.babyBlue;
};
