import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/nl';

dayjs.locale('nl');

type DateOptions = Dayjs | Date | number | string;

export const formatDate = (value: DateOptions, format?: string): string => {
  return dayjs(value).format(format || 'DD-MM-YYYY');
};

export const formatDateTime = (value: DateOptions): string => {
  return dayjs(value).format('DD-MM-YYYY HH:mm:ss');
};

export const formatDateToUnix = (value: DateOptions): number => {
  return dayjs(value).unix();
};
