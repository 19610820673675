export const formatMobileMenuItems = (items) => {
    const mobMenItems: MobileMenuItemsType = [];
    items.forEach((item) => {
      if (item.nestedNavigation) {
        item.nestedNavigation.forEach((item) => {
          mobMenItems.push({
            title: item.title,
            slug: item.dropdownSlug,
          });
        })
      } else {
        mobMenItems.push(item);
      }
    })
    return mobMenItems;
  }

type MobileMenuItemsType = {
  title?: string;
  nestedNavigation?: {
    title?: string;
    dropdownSlug?: string;
    dropdownDescription?: string;
  }[];
  slug?: string
}[]
