import * as React from 'react';
import * as RadixModal from '@radix-ui/react-dialog';

import CloseIcon from 'vectors/cross.svg';
import { Heading } from 'common/typography';

import { Button } from '../Button';
import {
  ModalContent,
  ModalClose,
  ModalFooter,
  ModalOverlay,
  ModalWrapper,
  ModalHeader,
  ModalScrollContainer,
} from './styled';

export const Modal: React.FC<ModalProps> = ({
  children,
  actions,
  size = 'large',
  showClose = true,
  title,
  triggerText,
  open,
  setOpen,
  $withScroll,
}) => {
  return (
    <RadixModal.Root open={open} onOpenChange={setOpen}>
      {triggerText && (
        <RadixModal.Trigger asChild>
          <Button variant="secondary">{triggerText}</Button>
        </RadixModal.Trigger>
      )}
      <RadixModal.Portal>
        <ModalOverlay>
          <ModalWrapper size={size} $withScroll={$withScroll}>
            <ModalHeader>
              <Heading as="h4" margin="0">
                {title}
              </Heading>
              {showClose && (
                <ModalClose>
                  <CloseIcon />
                </ModalClose>
              )}
            </ModalHeader>
            <ModalScrollContainer>
              <ModalContent>{children}</ModalContent>
            </ModalScrollContainer>
            {Boolean(actions) && actions && actions.length > 0 && (
              <ModalFooter>
                {actions.map((action, index) => (
                  <button key={`modalAction_${index}`} onClick={action.onClick}>
                    {action.label}
                  </button>
                ))}
              </ModalFooter>
            )}
          </ModalWrapper>
        </ModalOverlay>
      </RadixModal.Portal>
    </RadixModal.Root>
  );
};

type ModalAction = {
  onClick: () => void;
  label: string;
};

export type ModalProps = {
  children: React.ReactNode;
  actions?: ModalAction[];
  open?: boolean;
  setOpen?: (open: boolean) => void;
  showClose?: boolean;
  size?: 'small' | 'medium' | 'large';
  title?: string;
  triggerText?: string;
  $withScroll?: boolean;
};
