import { GraphQLClient, Variables } from 'graphql-request';

import { getApiUrl } from 'services';

export const fetcher = async (query: string, variables?: Variables) => {
  const endpoint = getApiUrl();

  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': process.env.NEXT_PUBLIC_API_KEY as string,
    },
  });

  return await graphQLClient.request(query, variables);
};
