import * as React from 'react';

import { ImageContainer } from './styled';

export const Image = ({ alt, src, margin, borderRadius }: ImageProps) => {
  return (
    <ImageContainer {...{ margin, borderRadius }}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img src={src} alt={alt} />
    </ImageContainer>
  );
};

type ImageProps = {
  alt: string;
  borderRadius?: number;
  src: string;
  margin?: string;
};
