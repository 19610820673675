import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const HamburgerButton = styled.button(
  ({ theme }) => css`
    display: flex;
    appearance: none;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    align-items: center;
    height: 32px;
    gap: 12px;
    color: ${theme.colors.nightBlue};
    font-weight: 900;
    font-size: 16px;
    font-family: ${theme.fonts.lato};

    ${media.tablet`
      display: none;
    `}
  `,
);

export const HamburgerBox = styled.span`
  width: 16px;
  height: 24px;
  display: inline-block;
  position: relative;
`;

export const HamburgerInner = styled.span<HamburgerInnerProps>(
  ({ theme, isOpen }) => css`
    display: block;
    top: 7px;
    margin-top: -2px;
    width: 16px;
    height: 2px;
    background-color: ${theme.colors.nightBlue};
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 16px;
      height: 2px;
      background-color: ${theme.colors.nightBlue};
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }

    &:before {
      top: 6px;
      transition-property: transform, opacity;
      transition-timing-function: ease;
      transition-duration: 0.15s;
    }

    &:after {
      top: 12px;
    }

    ${isOpen &&
    css`
      top: 8px;
      transform: translate3d(0, 5px, 0) rotate(45deg);

      &:before {
        display: none;
      }

      &:after {
        transform: translate3d(0, -12px, 0) rotate(-90deg);
      }
    `}
  `,
);

type HamburgerInnerProps = {
  isOpen: boolean;
};
