import * as React from 'react';
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import * as CFRichTextTypes from '@contentful/rich-text-types';
import * as Contentful from 'contentful';

import { Image } from 'common/general';
import { Button } from 'common/interaction';
import { Paragraph, Heading } from 'common/typography';

import { RichTextBlockContainer, RichTextDownloadButtonContainer } from './styled';

export const RichTextBlock = ({ data }: RichTextBlockProps) => {
  const richTextOptions: Options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
      [BLOCKS.HEADING_1]: (node, children) => <Heading>{children}</Heading>,
      [BLOCKS.HEADING_2]: (node, children) => <Heading as="h2">{children}</Heading>,
      [BLOCKS.HEADING_3]: (node, children) => <Heading as="h3">{children}</Heading>,
      [BLOCKS.HEADING_4]: (node, children) => <Heading as="h4">{children}</Heading>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, file } = node.data.target.fields;
        
        if (file.contentType.startsWith('image')) {
          return <Image src={file.url} alt={title} margin="24px 0" />;
        }
        
        return null;
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const { file, label } = node.data.target.fields;

        return (
          <RichTextDownloadButtonContainer>
            <Button
              href={file?.fields.file.url}
              buttonType="link"
              isExternalHref
              
            >
              {label}
            </Button>
          </RichTextDownloadButtonContainer>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a 
          href={node.data.uri}
          target='_blank'
          rel="noreferrer"
          >
            {children}
          </a>
        );
      },
    },
  };

  return (
    <RichTextBlockContainer>
      {documentToReactComponents(data as Contentful.EntryFields.RichText, richTextOptions)}
    </RichTextBlockContainer>
  );
};

type RichTextBlockProps = {
  data?: CFRichTextTypes.Block | CFRichTextTypes.Inline;
};
