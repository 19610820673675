import styled, { css } from 'styled-components';

export const ImageContainer = styled.div<ImageContainerProps>(
  ({ margin, borderRadius }) => css`
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    margin: ${margin || 0};
    border-radius: ${borderRadius || 0}px;

    & > img {
      object-fit: contain;
      width: 100%;
      height: auto;
      border-radius: ${borderRadius || 0}px;
    }
  `,
);

type ImageContainerProps = {
  borderRadius?: number;
  margin?: string;
};
