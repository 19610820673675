import styled, { css } from 'styled-components';

import theme from 'styles/theme';
import { media } from 'styles/utils';

export const headingSizes = {
  h1: {
    mobile: '32px',
    desktop: '42px',
  },
  h2: {
    mobile: '32px',
    desktop: '42px',
  },
  h3: {
    mobile: '20px',
    desktop: '24px',
  },
  h4: {
    mobile: '20px',
    desktop: '20px',
  },
  h5: {
    mobile: '16px',
    desktop: '18px',
  },
  h6: {
    mobile: '14px',
    desktop: '16px',
  },
};

export const Heading = styled.h1<HeadingProps>(
  ({ as, lineHeight, color = 'nightBlue', margin, align }) => css`
    font-weight: 800;
    font-family: ${theme.fonts.montserrat};
    font-size: ${headingSizes[as || 'h1'].mobile};
    line-height: ${lineHeight || 'normal'};
    color: ${theme.colors[color]};
    margin: ${margin || '24px 0'};
    text-align: ${align || 'left'};

    ${media.tablet`
      font-size: ${headingSizes[as || 'h1'].desktop};
    `}

    & > a {
      text-decoration: none;
      color: inherit;
    }
  `,
);

export type HeadingProps = {
  align?: 'center' | 'left' | 'right';
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  color?: 'babyBlue' | 'nightBlue' | 'white';
  lineHeight?: string;
  margin?: string;
};
