import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const HeaderReadMoreButton = styled.button<HeaderReadMoreProps>(
  ({ theme, isDark }) => css`
    display: none;
    gap: 8px;
    color: ${isDark ? theme.colors.nightBlue : theme.colors.white};
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 8;
    background: transparent;
    align-items: center;
    border: 0;
    font-size: 16px;
    font-weight: 900;
    cursor: pointer;
    transition: opacity 0.15s ease;

    @media (hover: hover) {
      &:hover {
        opacity: 0.6;
      }
    }

    & > svg {
      fill: ${isDark ? theme.colors.nightBlue : theme.colors.white};
    }

    ${media.desktop`
      display: flex;
      bottom: 24px;
    `}

    ${media.large`
      bottom: 48px;
    `}

    @media screen and (max-height: 576px) and (min-width: 768px) {
      display: none;
    }
  `,
);

type HeaderReadMoreProps = {
  isDark?: boolean;
};
