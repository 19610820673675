import * as React from 'react';

import { HamburgerButton, HamburgerBox, HamburgerInner } from './styled';

export const Hamburger = ({ onClick, isOpen }: HamburgerProps) => {
  return (
    <HamburgerButton onClick={() => onClick(!isOpen)}>
      <HamburgerBox>
        <HamburgerInner isOpen={isOpen} />
      </HamburgerBox>
      Menu
    </HamburgerButton>
  );
};

type HamburgerProps = {
  onClick: (boolean: boolean) => void;
  isOpen: boolean;
};
