import * as i from 'types';

// This function calculates all the outliers from the results. It works as follows:
// So if your answers in the likert scale (1-10) were:
// 5-7-2-8-9-3-1-6-5-7-8
// To generate the results it will take all the "Outliers" from that set of numbers.
// It will always take 3 negative results and 2 positive results
// So in this case the outlying numbers will be: 2,3,1 (negative) and 9,8 (positive).
// When we have the question with the outlying answers then we will show the corresponding
// answer to the user.
export const getQuestionnaireResults = (
  questions: i.TypePageQuestionnaireFields['questions'],
  queryParams: { [key: string]: string },
) => {
  const questionIds = questions?.map((question) => question.sys.id);
  const initialAnswers: { id: string; value: number }[] = [];
  const answers: { id: string; value: number }[] = [];

  Object.entries(queryParams).forEach(([key, value]) => {
    if (questionIds?.includes(key)) {
      const answer = { id: key, value: Number(value) };
      answers.push(answer);
      initialAnswers.push(answer);
    }
  });

  const lastFourAnswers = answers.slice(7);
  const firstSevenAnswers = answers.slice(0, 7);
  const outliers: { id: string; value?: number; }[] = [];

  // First, check the last four questions. They have the highest priority. An outlier is four or higher.
  lastFourAnswers.forEach((answer) => {
    if (answer.value >= 4) outliers.push(answer);
  });

  // Lastly, if the amount of outliers is less than 5, fill the outliers with the next lowest score of the first seven questions.
  const sortFirstSevenByAnswer = firstSevenAnswers.sort((a, b) => a.value - b.value);

  sortFirstSevenByAnswer.forEach((answer) => {
    if (outliers.length === 5) return null;
    outliers.push(answer)
  })

  const questionAnswers: { id: string; answer?: string; }[] = [];

  // Loop through the outliers to maintain the order of last four question > first seven questions.
  outliers.forEach((outlier) => {
    if (!outlier.value) return null;

    const outlierQuestionData = questions?.find((question) => question.sys.id === outlier.id);

    if (!outlierQuestionData) return null;
    
    questionAnswers.push({
      id: outlierQuestionData.sys.id,
      answer:
       outlier.value <= 5
          ? outlierQuestionData?.fields.disagreeAnswer
          : outlierQuestionData?.fields.agreeAnswer,
    });
  });

  return questionAnswers;
};
