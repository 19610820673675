import * as React from 'react';
import { throttle } from 'lodash';

export const useDocumentScrollThrottled = () => {
  const [scrollPosition, setScrollPosition] = React.useState(0);

  const handleDocumentScroll = () => {
    const { scrollTop } = document.documentElement || document.body;

    setScrollPosition(scrollTop);
  };

  const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 100);

  React.useEffect(() => {
    window.addEventListener('scroll', handleDocumentScrollThrottled);

    return () => window.removeEventListener('scroll', handleDocumentScrollThrottled);
  }, []);

  return Boolean(scrollPosition);
};
