export const validation = {
  required: {
    required: 'Dit veld is verplicht',
  },
  email: {
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Het lijkt erop dat dit geen geldig e-mail adres is',
    },
  },
};
