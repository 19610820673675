import styled, { css, keyframes } from 'styled-components';

import theme from 'styles/theme';
import { media } from 'styles/utils';

import { ButtonProps } from './';

export const buttonLoaderAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
`;

export const StyledButtonLoader = styled.div`
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 6px;
    height: 6px;
    margin-right: 3px;
    background-color: ${theme.colors.white};
    border-radius: 100%;
    animation: ${buttonLoaderAnimation} 1.4s infinite ease-in-out both;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }

    &:nth-child(2) {
      animation-delay: -0.16s;
    }
  }
`;

export const ButtonIcon = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  display: flex;
  align-items: center;
`;

export const StyledButton = styled.button<ButtonProps>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  flex-direction: row;
  position: relative;
  min-height: 48px;
  padding: 12px 24px;
  margin: ${({ margin }) => margin || 0};
  color: ${theme.colors.white};
  background-color: ${theme.colors.babyBlue};
  font-family: ${theme.fonts.lato};
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  border-radius: 8px;
  border: 0;

  span {
    text-decoration: none;
  }

  svg {
    fill: ${theme.colors.white};
    width: 24px;
    height: 24px;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${theme.colors.babyBlue60};
    }
  }

  &:focus {
    background-color: ${theme.colors.babyBlue};
  }

  ${media.desktop`
    transition: background-color 0.15s, border-color 0.15s;
  `}

  ${({ variant, theme }) =>
    variant === 'secondary' &&
    css`
      background-color: transparent;
      border: 2px solid ${theme.colors.nightBlue};
      color: ${theme.colors.nightBlue};

      @media (hover: hover) {
        &:hover {
          background-color: transparent;
          border: 2px solid ${theme.colors.babyBlue};
          color: ${theme.colors.babyBlue};

          ${ButtonIcon} svg {
            fill: ${theme.colors.babyBlue};
          }
        }
      }

      &:focus {
        background-color: transparent;
      }

      ${ButtonIcon} svg {
        fill: ${theme.colors.nightBlue};
      }
    `}

  ${({ variant, theme }) =>
    variant === 'success' &&
    css`
      background-color: ${theme.colors.aquaGreen};
      border: 0;
      color: ${theme.colors.white};

      @media (hover: hover) {
        &:hover {
          background-color: ${theme.colors.aquaGreen60};
          color: ${theme.colors.white};

          ${ButtonIcon} svg {
            fill: ${theme.colors.white};
          }
        }
      }

      &:focus {
        background-color: ${theme.colors.aquaGreen};
      }

      ${ButtonIcon} svg {
        fill: ${theme.colors.white};
      }
    `}

  ${({ size }) =>
    size === 'auto' &&
    css`
      width: auto;
      min-width: auto;
    `}

  ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: not-allowed;

      &:hover,
      &:focus {
        background-color: ${theme.colors.babyBlue};
      }
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${theme.colors.white};
      background-color: ${theme.colors.babyBlue20};
      cursor: not-allowed;
      pointer-events: none;

      &:focus {
        background-color: ${theme.colors.babyBlue20};
      }

      ${media.desktop`
      transition: none;

      @media (hover: hover) {
        &:hover {
          background-color: ${theme.colors.babyBlue20};
        }
      }
    `}
    `};

  ${({ iconPosition }) =>
    iconPosition === 'right' &&
    css`
      flex-direction: row-reverse;

      ${ButtonIcon} {
        margin: 0 0 0 8px;
      }
    `}

  ${({ iconOnly }) =>
    iconOnly &&
    css`
      width: 48px;

      ${ButtonIcon} {
        margin: 0;
      }
    `}

  ${({ iconOnlyOnMobile }) =>
    iconOnlyOnMobile &&
    css`
      width: 48px;

      ${media.tablet`
        width: auto;
      `}

      span {
        display: none;

        ${media.tablet`
          display: block;
        `}
      }

      ${ButtonIcon} {
        margin: 0;

        ${media.tablet`
          display: none;
        `}
      }
    `}
`;
