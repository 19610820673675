import { ParsedUrlQuery } from 'querystring';

import { useRouter } from 'hooks';

type SetQueryParams = {
  duration?: number;
  query?: Query;
  pathname?: string;
  keepQuery?: ParsedUrlQuery;
};

export const useQueryParams = () => {
  const router = useRouter();

  const setQueryParams = ({ query, pathname, keepQuery }: SetQueryParams) => {
    if (!query) {
      if (!pathname) {
        router.replace({ search: undefined });
        return;
      }

      if (pathname) {
        router.replace({
          pathname,
          query: {
            ...keepQuery,
          },
          search: undefined,
        });
        return;
      }

      return;
    }

    for (const key in query) {
      if (query[key] === '') {
        delete query[key];
      }
    }

    router.replace({
      search: new URLSearchParams(query).toString(),
    });
  };

  const asyncSetQueryParams = ({
    query,
    pathname,
    keepQuery,
    duration = 250,
  }: SetQueryParams): Promise<void> =>
    new Promise((resolve) => {
      setQueryParams({ query, pathname, keepQuery });
      setTimeout(() => resolve(), duration);
    });

  return {
    queryParams: router.query as Record<string, string>,
    setQueryParams,
    asyncSetQueryParams,
  };
};

type Query = Record<string, string> | URLSearchParams;
