import * as React from 'react';

import { useScrollTo } from 'hooks';
import ChevronDownIcon from 'vectors/chevron_down.svg';

import { HeaderReadMoreButton } from './styled';

export const HeaderReadMore = ({ isDark, selector, scrollOffset }: HeaderReadMoreProps) => {
  const { scrollTo } = useScrollTo();

  return (
    <HeaderReadMoreButton
      isDark={isDark}
      onClick={() => scrollTo(`#${selector}`, scrollOffset || 200)}
    >
      Lees meer <ChevronDownIcon />
    </HeaderReadMoreButton>
  );
};

type HeaderReadMoreProps = {
  isDark?: boolean;
  selector: string;
  scrollOffset?: number;
};
