import styled, { css } from 'styled-components';

import theme from 'styles/theme';

export const Paragraph = styled.p<ParagraphProps>(
  ({ weight, size, lineHeight, color, margin, align }) => css`
    font-weight: ${weight || 400};
    font-size: ${size || 16}px;
    font-family: ${theme.fonts.lato};
    line-height: ${lineHeight || '24px'};
    color: ${color ? theme.colors[color] : theme.colors.nightBlue};
    margin: ${margin || '8px 0px'};
    text-align: ${align || 'left'};
  `,
);

export type ParagraphProps = {
  align?: 'center' | 'left' | 'right';
  color?: 'nightBlue60' | 'white';
  lineHeight?: string;
  margin?: string;
  size?: 12 | 14 | 16 | 18;
  weight?: 300 | 400 | 500 | 600 | 700 | 800 | 900;
};
