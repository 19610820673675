import * as React from 'react';

import { Button } from '../Button';
import { Modal } from '../Modal';
import { ConfirmationModalButtons } from './styled';

export const ConfirmationModal = ({
  description,
  confirmButtonText,
  open,
  setOpen,
  onConfirm,
}: ConfirmationModalProps) => {
  const onClickConfirm = () => {
    onConfirm();
    setOpen(false);
  };

  return (
    <Modal title="Let op" open={open} setOpen={setOpen}>
      {description}
      <ConfirmationModalButtons>
        <Button onClick={onClickConfirm}>{confirmButtonText || 'Bevestigen'}</Button>
        <Button variant="secondary" onClick={() => setOpen(false)}>
          Annuleren
        </Button>
      </ConfirmationModalButtons>
    </Modal>
  );
};

type ConfirmationModalProps = {
  description: string;
  confirmButtonText?: string;
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
  open: boolean;
};
