import styled, { css } from 'styled-components';

import { getBrandingColor } from 'services';

export const BrandingTriangle = styled.span<BrandingTriangleProps>(
  ({ index }) => css`
    position: relative;
    width: 32px;
    height: 32px;
    overflow: hidden;
    flex-shrink: 0;

    &:after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      right: 0;
      top: 0;
      border-style: solid;
      border-width: 0 32px 32px 0;
      border-left-color: transparent;
      border-bottom-color: transparent;
      border-top-color: transparent;
      border-right-color: ${getBrandingColor(index)};
    }
  `,
);

type BrandingTriangleProps = {
  index: number;
};
