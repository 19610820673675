export const getApiUrl = (): string => {
  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    case 'production':
      return 'https://bqk4untnk5ezxewszhbakdorem.appsync-api.eu-west-1.amazonaws.com/graphql';
    case 'acceptance':
      return 'https://ycojbrf7wbhixet6qezkjnldgu.appsync-api.eu-west-1.amazonaws.com/graphql';
    case 'development':
      return 'https://67gwyctmcncpfgd5ol3cp3lz6e.appsync-api.eu-west-1.amazonaws.com/graphql';
    default:
      return 'https://67gwyctmcncpfgd5ol3cp3lz6e.appsync-api.eu-west-1.amazonaws.com/graphql';
  }
};
