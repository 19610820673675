import styled from 'styled-components';

export const ConfirmationModalButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 48px;

  & > button {
    width: 100%;
  }
`;
