import * as i from 'types';
import { Entry } from 'contentful';

type PageBodyItemField = Entry<
  | i.TypeComponentAccordionBlockFields
  | i.TypeComponentContentBlockWithLinksFields
  | i.TypeComponentContentBlockWithGridFields
  | i.TypeComponentContentBlockWithStatisticsFields
  | i.TypeComponentContentBlockWithImageFields
  | i.TypeComponentContentBlockWithColumnsFields
  | i.TypeComponentContentBlockWithSidebarFields
  | i.TypeComponentContentBlockWithReviewsFields
  | i.TypeComponentQuoteBlock
>;

export const isTypePage = (
  item: i.TypeNavigationItemFields | i.TypeNavigationListFields | i.TypePageGeneralFields,
): item is i.TypePageGeneralFields => {
  return 'seo' in item;
};

export const isTypeNavigationList = (
  item: i.TypeNavigationItemFields | i.TypeNavigationListFields | i.TypePageGeneralFields,
): item is i.TypeNavigationListFields => {
  return 'navigationItems' in item;
};

export const isTypeHeaderHome = (
  item: Entry<
    i.TypeHeaderThemeFields | i.TypeHeaderConversationStarterFields | i.TypeHeaderHomeFields
  >,
): item is Entry<i.TypeHeaderHomeFields> => {
  return item.sys.contentType.sys.id === 'headerHome';
};

export const isTypeHeaderTheme = (
  item: Entry<
    i.TypeHeaderThemeFields | i.TypeHeaderConversationStarterFields | i.TypeHeaderHomeFields
  >,
): item is Entry<i.TypeHeaderThemeFields> => {
  return item.sys.contentType.sys.id === 'headerTheme';
};

export const isTypeHeaderConversationStarter = (
  item: Entry<
    i.TypeHeaderThemeFields | i.TypeHeaderConversationStarterFields | i.TypeHeaderHomeFields
  >,
): item is Entry<i.TypeHeaderConversationStarterFields> => {
  return item.sys.contentType.sys.id === 'headerConversationStarter';
};

export const isTypeAccordionBlock = (
  item: PageBodyItemField,
): item is Entry<i.TypeComponentAccordionBlockFields> => {
  return item.sys.contentType.sys.id === 'componentAccordionBlock';
};

export const isTypeContentBlockWithLinks = (
  item: PageBodyItemField,
): item is Entry<i.TypeComponentContentBlockWithLinksFields> => {
  return item.sys.contentType.sys.id === 'componentContentBlockWithLinks';
};

export const isTypeContentBlockWithGrid = (
  item: PageBodyItemField,
): item is Entry<i.TypeComponentContentBlockWithLinksFields> => {
  return item.sys.contentType.sys.id === 'componentContentBlockWithGrid';
};

export const isTypeContentBlockWithImage = (
  item: PageBodyItemField,
): item is Entry<i.TypeComponentContentBlockWithImageFields> => {
  return item.sys.contentType.sys.id === 'componentContentBlockWithImage';
};

export const isTypeContentBlockWithStatistics = (
  item: PageBodyItemField,
): item is Entry<i.TypeComponentContentBlockWithStatisticsFields> => {
  return item.sys.contentType.sys.id === 'componentContentBlockWithStatistics';
};

export const isTypeContentBlockWithColumns = (
  item: PageBodyItemField,
): item is Entry<i.TypeComponentContentBlockWithColumnsFields> => {
  return item.sys.contentType.sys.id === 'componentContentBlockWithColumns';
};

export const isTypeContentBlockWithReviews = (
  item: PageBodyItemField,
): item is Entry<i.TypeComponentContentBlockWithReviewsFields> => {
  return item.sys.contentType.sys.id === 'componentContentBlockWithReviews';
};

export const isTypeContentBlockWithSidebar = (
  item: PageBodyItemField,
): item is Entry<i.TypeComponentContentBlockWithSidebarFields> => {
  return item.sys.contentType.sys.id === 'componentContentBlockWithSidebar';
};

export const isTypeQuoteBlock = (
  item: PageBodyItemField,
): item is Entry<i.TypeComponentQuoteBlockFields> => {
  return item.sys.contentType.sys.id === 'componentQuoteBlock';
};

export const isTypeRichText = (
  item: PageBodyItemField,
): item is Entry<i.TypeComponentRichTextFields> => {
  return item.sys.contentType.sys.id === 'componentRichText';
};

export const isTypeComponentGridItem = (
  item: Entry<i.TypeComponentGridItemFields | i.TypeComponentToolsItemFields>,
): item is Entry<i.TypeComponentGridItemFields> => {
  return item.sys.contentType.sys.id === 'componentGridItem';
};

export const isTypeComponentToolsItem = (
  item: Entry<i.TypeComponentGridItemFields | i.TypeComponentToolsItemFields>,
): item is Entry<i.TypeComponentToolsItemFields> => {
  return item.sys.contentType.sys.id === 'componentToolsItem';
};
