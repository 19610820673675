import * as RadixAccordion from '@radix-ui/react-accordion';
import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const AccordionRootContainer = styled(RadixAccordion.Root)`
  width: 100%;
`;

export const AccordionChevron = styled.div(
  ({ theme }) => css`
    width: 24px;
    height: 24px;
    transition: transform 0.15s ease-in-out, color 0.15s ease;
    fill: ${theme.colors.nightBlue};
  `,
);

export const AccordionItemTitle = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.nightBlue};
    margin: 0;
    font-weight: 900;
    text-align: left;
    transition: color 0.15s ease;
  `,
);

export const AccordionHeader = styled(RadixAccordion.Header)<AccordionHeaderProps>(
  ({ theme, $hasSubtitle }) => css`
    margin: 0;
    background-color: transparent;
    border-bottom: ${theme.border.default};
    display: flex;
    gap: 24px;

    & > button {
      height: fit-content;
      width: 100%;
      flex: 1 1 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0;
      font-size: 16px;
      font-family: ${theme.fonts.lato};
      font-weight: 400;
      color: ${theme.colors.nightBlue};
      border: 0;
      background-color: transparent;
      cursor: pointer;

      ${media.desktop`
        height: ${$hasSubtitle ? '96px' : '72px'};
        padding: 0;
      `}
    }

    @media (hover: hover) {
      &:hover {
        & ${AccordionItemTitle}, & ${AccordionChevron} {
          color: ${theme.colors.babyBlue};
          fill: ${theme.colors.babyBlue};
        }
      }
    }

    &[data-state='open'] ${AccordionItemTitle} {
      color: ${theme.colors.babyBlue};
    }

    &[data-state='open'] ${AccordionChevron} {
      transform: rotate(180deg);
      fill: ${theme.colors.babyBlue};
    }
  `,
);

type AccordionHeaderProps = {
  $hasSubtitle?: boolean;
};

export const AccordionItemContainer = styled(RadixAccordion.Item)<AccordionItemContainerProps>(
  ({ $color, theme }) => css`
    &:last-child ${AccordionHeader} {
      border-bottom: 0;
    }

    &[data-state='open'] ${AccordionItemTitle} {
      color: ${$color};
    }

    &[data-state='open'] ${AccordionChevron} {
      fill: ${$color};
    }

    &[data-state='open'] ${AccordionContent} {
      border-bottom: ${theme.border.default};
    }

    &[data-state='open'] ${AccordionHeader} {
      border-bottom: 0;
    }

    @media (hover: hover) {
      &:hover {
        & ${AccordionItemTitle}, & ${AccordionChevron} {
          color: ${$color};
          fill: ${$color};
        }
      }
    }
  `,
);

type AccordionItemContainerProps = {
  $color?: string;
};

export const AccordionItemTitleContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;

  & > svg {
    flex-shrink: 0;
  }
`;

export const AccordionContent = styled(RadixAccordion.Content)(
  ({ theme }) => css`
    padding: 8px 0 16px;
    overflow: hidden;
    font-family: ${theme.fonts.lato};
    font-size: 16px;
    color: ${theme.colors.nightBlue};
    background-color: transparent;
  `,
);
