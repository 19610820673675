import styled from 'styled-components';

import { media } from 'styles/utils';
import { headingSizes } from 'common/typography/Heading';

export const RichTextBlockContainer = styled.div`
  h4 {
    font-size: ${headingSizes.h4.mobile};
    margin: 48px 0 24px;

    &:first-of-type {
      margin: 0 0 24px;
    }

    ${media.tablet`
      font-size: ${headingSizes.h4.desktop};
    `}
  }

  p {
    line-height: 24px;
    margin: 24px 0;

    &:first-of-type:first-child {
      margin-top: 0;
    }

    &:last-of-type:last-child {
      margin-bottom: 0;
    }
  }

  ol {
    padding-inline-start: 24px;
  }

  li {
    padding-left: 16px;
    margin-bottom: 24px;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const RichTextDownloadButtonContainer = styled.div`
  & a {
    display: block;
    width: fit-content;
  }
`;
