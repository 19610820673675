import * as i from 'types';
import * as React from 'react';
import * as RadixAccordion from '@radix-ui/react-accordion';
import * as Contentful from 'contentful';

import { getBrandingColor } from 'services';
import ChevronDownIcon from 'vectors/chevron_down.svg';
import { BrandingTriangle } from 'common/general';
import { Paragraph, RichTextBlock } from 'common/typography';

import {
  AccordionRootContainer,
  AccordionHeader,
  AccordionItemContainer,
  AccordionItemTitleContainer,
  AccordionContent,
  AccordionChevron,
  AccordionItemTitle,
} from './styled';

export const AccordionRoot: React.FC<AccordionProps> = ({
  children,
  defaultValue,
  type = 'single',
  collapsible = true,
}) => {
  return (
    <AccordionRootContainer
      {...{
        ...(type === 'multiple'
          ? {
              type,
              defaultValue: defaultValue as string[],
            }
          : {
              collapsible,
              type,
              defaultValue: defaultValue as string,
            }),
      }}
    >
      {children}
    </AccordionRootContainer>
  );
};

export const AccordionItem: React.FC<AccordionItemProps> = ({
  children,
  item,
  index,
  isRichTextContent,
  icon,
}) => {
  return (
    <AccordionItemContainer
      value={item.id}
      key={item.id}
      $color={getBrandingColor(index, item.brandingColor)}
    >
      <AccordionHeader $hasSubtitle={Boolean(item.subtitle)}>
        {children}
        <RadixAccordion.Trigger>
          <AccordionItemTitleContainer>
            {icon ? icon : <BrandingTriangle index={index} />}
            <div>
              <AccordionItemTitle>{item.title}</AccordionItemTitle>
              {item.subtitle && (
                <Paragraph margin="0" color="nightBlue60">
                  {item.subtitle}
                </Paragraph>
              )}
            </div>
          </AccordionItemTitleContainer>
          <AccordionChevron aria-hidden>
            <ChevronDownIcon />
          </AccordionChevron>
        </RadixAccordion.Trigger>
      </AccordionHeader>
      <AccordionContent>
        {isRichTextContent ? (
          <RichTextBlock data={item.description as Contentful.EntryFields.RichText} />
        ) : (
          item.description
        )}
      </AccordionContent>
    </AccordionItemContainer>
  );
};

export const Accordion = {
  Root: AccordionRoot,
  Item: AccordionItem,
};

export type AccordionProps = {
  collapsible?: boolean;
  defaultValue?: string | string[];
  type?: 'single' | 'multiple';
  isOpenByDefault?: i.TypeComponentAccordionBlockFields['openByDefault'];
};

export type AccordionItemProps = {
  isRichTextContent?: boolean;
  icon?: React.ReactNode;
  index: number;
  item: {
    id: string;
    title: string;
    subtitle?: string;
    description: i.TypeComponentAccordionBlockFields['description'];
    brandingColor?: 'blue' | 'green' | 'red';
  };
};
