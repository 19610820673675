import * as RadixModal from '@radix-ui/react-dialog';
import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const ModalOverlay = styled(RadixModal.Overlay)(
  ({ theme }) => css`
    background: ${theme.colors.nightBlue40};
    position: fixed;
    inset: 0;
    display: grid;
    place-items: center;
    overflow-y: auto;
    z-index: 10;
  `,
);

export const ModalWrapper = styled(RadixModal.Content)<ModalContentProps>(
  ({ theme, size, $withScroll }) => css`
    position: relative;
    min-height: 320px;
    max-height: calc(100vh - 192px);
    height: ${$withScroll ? '100%' : 'auto'};
    overflow: hidden;
    min-width: 300px;
    width: calc(100% - 48px);
    font-size: 16px;
    font-family: ${theme.fonts.lato};
    background-color: ${theme.colors.white};
    border-radius: 8px;

    ${media.tablet`
      width: calc(100% - 96px);
    `}

    ${media.desktop`
      width: 768px;
    `}

    ${media.large`
      width: 864px;
    `}

    ${size === 'medium' &&
    css`
      max-width: 720px;
    `};

    ${size === 'small' &&
    css`
      max-width: 460px;
    `};
  `,
);

export type ModalContentProps = {
  size?: 'small' | 'medium' | 'large';
  $withScroll?: boolean;
};

export const ModalHeader = styled.div(
  ({ theme }) => css`
    border-bottom: ${theme.border.default};
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    height: 72px;
  `,
);

export const ModalScrollContainer = styled.div`
  overflow-y: auto;
  height: calc(100% - 72px);
`;

export const ModalContent = styled.div`
  padding: 24px;
`;

export const ModalFooter = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 30px;
    border-top: ${theme.border.default};

    button + button {
      margin-left: 8px;
    }
  `,
);

export const ModalClose = styled(RadixModal.Close)(
  ({ theme }) => css`
    width: 24px;
    height: 24px;
    border: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.babyBlue};
    border-radius: 100%;
    padding: 0;
    transition: background-color 0.15s ease;

    @media (hover: hover) {
      &:hover {
        background-color: ${theme.colors.babyBlue60};
      }
    }

    svg {
      width: 100%;
      height: 100%;
      fill: ${theme.colors.white};
    }
  `,
);
